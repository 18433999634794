import { RouteRecordRaw } from "vue-router";

export const publicOnlyRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
  },
  {
    name: "SignIn",
    path: "/sign-in",
    component: () => import("@/views/sign-in.page.vue"),
  },
  // {
  //   name: "SignUp",
  //   path: "/sign-up",
  //   component: () => import("@/views/sign-up.page.vue"),
  // },
  {
    name: "PasswordForgot",
    path: "/password-forgot",
    component: () => import("@/views/password-forgot.page.vue"),
  },
  {
    name: "VerifyEmail",
    path: "/verify-email",
    component: () => import("@/views/verify-email.page.vue"),
  },
  {
    name: "PublicChampionship",
    path: "/public/championship/:id/",
    component: () => import("@/views/championship-public.page.vue"),
    children: [
      {
        name: "PublicOverview",
        path: "",
        component: () => import("@/views/championship-home-public.page.vue"),
      },
    ],
  },
].map((route: RouteRecordRaw) => {
  if (!route.meta) route.meta = {};
  route.meta.private = false;
  route.meta.public = true;
  return route;
});
