import { RegistrationImportBody } from "@/types/Registration-Import.Body";
import { reactive, computed, watch } from "vue";

import { useValidatorService } from "@/services/validation.service";
import { InternalRuleItem, Rule } from "async-validator";
import { useI18n } from "vue-i18n";
import { ClassResponse } from "@/types/Class.Response";
import { useChampionshipClassStore } from "./Championship-Class.store";

const state = reactive({
  url: "",
  gender: "",
  ageClassId: "",
  data: {
    headers: [] as string[],
    items: [] as any[],
  },
  mapping: {
    firstName: "vorname",
    lastName: "nachname",
    weightCategory: "gewicht",
    yearOfBirth: "geboren",
    clubName: "verein",
    countryCodeIOC: "nation",
    associationCode2: "verband",
    externalId: "externeId",
    wasWeighted: "gewogen",
    hasPaid: "bezahlt",
  },
  competitors: [] as RegistrationImportBody[],
});

let _weightClasses = [] as any[];

const setWeightClasses = (weightClasses: any[] = []) => {
  if (Array.isArray(weightClasses)) {
    _weightClasses = weightClasses.map((item: ClassResponse) => {
      return {
        id: item.weightClassId,
        text: item.weightClassText,
        classId: item.id,
        ageClassId: item.ageClassId,
        gender: item.gender,
      };
    });
  } else {
    _weightClasses = [];
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRegistrationImport() {
  const { t } = useI18n();

  const validateMapField = (value: string) => {
    if (!value) return new Error(t("errors.field-is_required"));
    if (
      state.data.headers.length > 0 &&
      state.data.headers.findIndex((item) => item == value) == -1
    )
      return new Error(t("errors.column-does-not-exists") + " " + value);
    return true;
  };

  const _rules: Record<string, Rule> = {
    gender: {
      required: true,
      validator: (rule: InternalRuleItem, value: string) => {
        if (!value) return new Error(t("errors.field-is_required"));
        if (!(value == "M" || value == "F" || value == "Mixed"))
          return new Error(t("errors.gender-wrong-value"));
        return [];
      },
    },
    ageClassId: {
      validator: (rule: InternalRuleItem, value: string) => {
        if (!value) return new Error(t("errors.field-is_required"));
        return [];
      },
    },
    mapping: {
      type: "object",
      required: true,
      fields: {
        clubName: {
          validator: (rule: InternalRuleItem, value: string) => {
            return validateMapField(value);
          },
        },
        weightCategory: {
          validator: (rule: InternalRuleItem, value: string) => {
            return validateMapField(value);
          },
        },
        firstName: {
          validator: (rule: InternalRuleItem, value: string) => {
            return validateMapField(value);
          },
        },
        lastName: {
          validator: (rule: InternalRuleItem, value: string) => {
            return validateMapField(value);
          },
        },
        yearOfBirth: {
          validator: (rule: InternalRuleItem, value: string) => {
            return validateMapField(value);
          },
        },
        countryCodeIOC: {
          validator: (rule: InternalRuleItem, value: string) => {
            return validateMapField(value);
          },
        },
        associationCode2: {
          validator: (rule: InternalRuleItem, value: string) => {
            return validateMapField(value);
          },
        },
      },
    },
  };

  const { validate, errorMap: _errorMap } = useValidatorService(state, _rules);

  const _weightIdList = reactive({
    items: [] as string[],
  });

  const weightIdList = computed(() => _weightIdList.items);

  const errorMap = computed(() => _errorMap.value);

  const { classes } = useChampionshipClassStore();

  setWeightClasses(classes.value);

  watch(reactive(classes), () => {
    setWeightClasses(classes.value);
  });

  const refreshWeightCategoryList = () => {
    const genderValue = state.gender;
    validate();
    if (
      Array.isArray(_weightClasses) &&
      genderValue != "" &&
      state.ageClassId != ""
    ) {
      _weightIdList.items = _weightClasses
        .filter(
          (item: ClassResponse) =>
            item.gender.toLocaleLowerCase() ==
              (genderValue == "M"
                ? "m"
                : genderValue == "F"
                ? "f"
                : genderValue) && item.ageClassId == state.ageClassId
        )
        .map((item: ClassResponse) => item.id);
    } else {
      _weightIdList.items = [];
    }
  };

  watch(state, refreshWeightCategoryList);

  const clearStore = () => {
    state.ageClassId = "";
    state.competitors = [];
    state.gender = "";
    state.url = "";
    state.data = {
      headers: [] as string[],
      items: [],
    };
  };

  const setData = (data: any[]) => {
    const result = {
      headers: [] as string[],
      items: data,
    };

    if (data.length >= 0) {
      for (const prop in data[0]) {
        result.headers.push(prop);
      }
    }

    state.data = result;
  };

  const setCompetitors = (competitors: RegistrationImportBody[] = []) => {
    state.competitors = competitors;
  };

  // action area
  const mapWeightCategoryToClassId = (weightClassText: string | undefined) => {
    if (
      Array.isArray(_weightClasses) &&
      state.gender != "" &&
      state.ageClassId != "" &&
      weightClassText != "" &&
      weightClassText != undefined
    ) {
      const foundClass = _weightClasses.find(
        (item) =>
          item.gender.toLocaleLowerCase() == state.gender.toLocaleLowerCase() &&
          item.ageClassId == state.ageClassId &&
          item.text.toString() ==
            ((weightClassText?.toString() || "").indexOf("-") >= 0 ||
            (weightClassText?.toString() || "").indexOf("+") >= 0
              ? weightClassText + ""
              : "+" + weightClassText)
      );

      return foundClass?.classId || undefined;
    } else {
      return "unknown";
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapCompetitors = (competitors: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return competitors.map((item: any) => {
      return {
        firstName: item[state.mapping.firstName]?.toString() || "unknown",
        lastName: item[state.mapping.lastName]?.toString() || "unknown",
        weightCategory: item[state.mapping.weightCategory]?.toString() || "",
        classId: mapWeightCategoryToClassId(item[state.mapping.weightCategory]),
        yearOfBirth: item[state.mapping.yearOfBirth]?.toString() || "",
        clubName: item[state.mapping.clubName]?.toString() || "",
        countryCodeIOC: item[state.mapping.countryCodeIOC]?.toString() || "",
        associationCode2:
          item[state.mapping.associationCode2]?.toString() || "",
        externalId: item[state.mapping.externalId]?.toString() || "",
        wasWeighted: !!item[state.mapping.wasWeighted] || false,
        hasPaid: !!item[state.mapping.hasPaid] || false,
      };
    });
  };

  return {
    clearStore,
    validate,
    state,
    weightIdList,
    errorMap,
    setData,
    setCompetitors,
    mapWeightCategoryToClassId,
    mapCompetitors,
  };
}
