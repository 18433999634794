import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group mb-3" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "text",
      class: _normalizeClass(["form-control", {
        'is-invalid': !!_ctx.invalidValue,
      }]),
      maxlength: "5",
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
      placeholder: "01:20"
    }, null, 42, _hoisted_2),
    _createElementVNode("div", {
      class: "invalid-feedback pointer",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.applySuggestion && _ctx.applySuggestion(...args))),
      innerHTML: _ctx.invalidValue
    }, null, 8, _hoisted_3)
  ]))
}