
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    modelPoints: {
      type: String,
      default: () => "00",
    },
  },
  setup: (props, { emit }) => {
    const hasIppon = computed(() => props.modelPoints == "10");
    const hasWazaAri = computed(() => props.modelPoints == "07");
    const hasYuko = computed(() => props.modelPoints == "05");
    const hasGoldenScore = computed(() => props.modelPoints == "01");

    return {
      onClick: (points: string) => {
        emit(
          "update:modelPoints",
          points
          // points == "00"
          //   ? "00"
          //   : hasIppon.value || points == "10"
          //   ? "10"
          //   : hasWazaAri.value || points == "07"
          //   ? "07"
          //   : points
        );
      },
      hasIppon,
      hasWazaAri,
      hasYuko,
      hasGoldenScore,
    };
  },
});
