
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";

import DefaultOffcanvas from "../common/offcanvas/default.offcanvas.vue";

import { Rule } from "async-validator";
import { UpdateCompetitionResultBody } from "../../types/Competition-Result.Body";
import TimePickerInput from "../common/input/time-picker.input.vue";
import PointPickerInput from "../common/input/point-picker.input.vue";
import { timeout } from "@/services/timeout.service";
import ButtonLoadingIndicator from "../common/loading/button.loading-indicator.vue";
import CompetitionCompactItem from "./competition-compact.item.vue";
import { useChampionshipClassListStore } from "@/stores/Championship-Class-List.store";
import { useChampionshipDashboardStore } from "@/stores/Championship-Dashboard.store";
import { useRoute } from "vue-router";
import { useChampionshipStore } from "@/stores/Championship.store";

export default defineComponent({
  props: ["canvasId"],
  components: {
    DefaultOffcanvas,
    TimePickerInput,
    PointPickerInput,
    ButtonLoadingIndicator,
    CompetitionCompactItem,
  },
  setup: (props) => {
    const timeHasError = ref(false);
    const { selectedCompetition, createOrUpdateResult, selectedClassList } =
      useChampionshipClassListStore();

    const { championship } = useChampionshipStore();

    const { loadNextFightPerCategory } = useChampionshipDashboardStore();

    let result = reactive({} as UpdateCompetitionResultBody);
    const updateResult = () => {
      result.id = selectedCompetition.value.id;
      result.listId = selectedCompetition.value.listId;
      result.resultId = selectedCompetition.value.result?.id;
      result.isDisqualified =
        selectedCompetition.value?.result?.isDisqualified == true
          ? true
          : false;
      result.time = selectedCompetition.value.result?.time || "00:00";
      result.athleteAPoints =
        selectedCompetition.value.result?.athleteAPoints || "00";
      result.athleteBPoints =
        selectedCompetition.value.result?.athleteBPoints || "00";
      result.athleteAShido =
        selectedCompetition.value.result?.athleteAShido || "00";
      result.athleteBShido =
        selectedCompetition.value.result?.athleteBShido || "00";
    };

    watch(selectedCompetition, () => {
      updateResult();
    });

    const rules: Record<string, Rule> = {};

    watch(result, () => {
      if (Number(result.athleteAPoints) > Number(result.athleteBPoints)) {
        result.isAthleteAWinner = true;
      } else {
        result.isAthleteAWinner = false;
      }
    });

    var closeButton: any = null;
    onMounted(() => {
      closeButton = document.querySelector(
        "#" + props.canvasId + " .btn-close"
      );
    });

    const isLoading = ref(false);
    const save = () => {
      if (
        result.athleteAPoints == result.athleteBPoints ||
        championship.value?.id == undefined
      )
        return;

      isLoading.value = true;

      createOrUpdateResult(selectedCompetition.value.listId, result)
        .then(() => {
          loadNextFightPerCategory(championship.value?.id || "");
        })
        .finally(() => {
          if (closeButton) closeButton.click();
          timeout(() => (isLoading.value = false));
        });
    };

    const handleHotKeys = (event: any) => {
      switch (event.key) {
        case "Enter":
          if (event.ctrlKey == true) save();
          break;

        case "a":
          result.athleteAPoints = "10";
          break;
        case "s":
          result.athleteAPoints = "07";
          break;
        case "d":
          result.athleteAPoints = "05";
          break;
        case "f":
          result.athleteAPoints = "01";
          break;
        case "g":
          result.athleteAPoints = "00";
          break;
        case "j":
          result.athleteBPoints = "10";
          break;
        case "k":
          result.athleteBPoints = "07";
          break;
        case "l":
          result.athleteBPoints = "05";
          break;
        case "ö":
          result.athleteBPoints = "01";
          break;
        case "ä":
          result.athleteBPoints = "00";
          break;
        default:
          return;
      }
      event.preventDefault();
    };
    const registrateKeyEvents = (isVisible: boolean) => {
      if (isVisible) {
        window.addEventListener("keydown", handleHotKeys);
      } else {
        window.removeEventListener("keydown", handleHotKeys);
      }
    };

    return {
      nextCompetitions: computed(() => {
        if (
          selectedClassList &&
          selectedClassList.value &&
          selectedClassList.value.competitions &&
          selectedCompetition.value &&
          selectedCompetition.value.fightNumber
        ) {
          const competitions = selectedClassList.value.competitions;
          let counter = 2;
          const nextCompetitions = competitions
            .filter((competition) => {
              const isFightLess =
                competition.athleteAIsEmpty || competition.athleteBIsEmpty;

              const fightNumberIsLowerEqualCurrent =
                Number(competition.fightNumber) <=
                Number(selectedCompetition.value.fightNumber);

              return isFightLess || fightNumberIsLowerEqualCurrent
                ? false
                : true;
            })
            .filter((competition) => !!competition)
            .filter((competition, index) => index < counter);

          return nextCompetitions.length > 0 ? nextCompetitions : undefined;
        }
        return undefined;
      }),
      updateDisplay: (visible: boolean) => {
        registrateKeyEvents(visible);
      },
      disableSave: computed(() => {
        return (
          Number(result.athleteAPoints || 0) ==
            Number(result.athleteBPoints || 0) || timeHasError.value == true
        );
      }),
      result,
      selectedCompetition,
      save,
      isLoading,
      timeHasError,
    };
  },
});
