
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: () => {
        "00:00";
      },
    },
  }, //"00:00"
  setup: (props, { emit }) => {
    const hasError = (value: string | undefined) => {
      if (value) {
        const minutes = value.split(":")[0];
        const validatedMinutes = validateMinutes(value);

        const seconds = value.split(":")[1];
        const validatedSeconds = validateSeconds(value);

        if (minutes != validatedMinutes || seconds != validatedSeconds) {
          return (
            'Invalid time format (mm:ss) <br/> do you mean "minutes: ' +
            validatedMinutes +
            " seconds: " +
            validatedSeconds +
            '"'
          );
        }

        if (value.match("[0-5][0-9]:[0-5][0-9]") == null) {
          return "Invalid time format (mm:ss)";
        }
      }
      return false;
    };
    const invalidValue = computed(() => {
      const value = props.modelValue;
      return hasError(value);
    });

    const applySuggestion = () => {
      const value = props.modelValue;
      const validatedMinutes = validateMinutes(value);
      const validatedSeconds = validateSeconds(value);

      const newValue = validatedMinutes + ":" + validatedSeconds;

      emit("update:modelValue", newValue);
      emit("update:modelHasError", !!hasError(newValue));
    };

    // validate minutes and return correct value
    const validateMinutes = (value: string) => {
      let minutes = value;

      if (value.indexOf(":") >= 0) {
        minutes = value.split(":")[0];
      }

      if (minutes.indexOf(".") >= 0) {
        minutes = minutes.replace(".", ":").split(":")[0];
      }

      const minutesNew =
        minutes.match("[0-5]?[0-9]") == null
          ? "00"
          : Number(minutes) <= 0
          ? "00"
          : Number(minutes) >= 60
          ? "59"
          : Number(minutes) < 10
          ? "0" + Number(minutes)
          : minutes.match("[0-5][0-9]") == null
          ? "00"
          : Number(minutes);

      return minutesNew;
    };

    // validate seconds and return correct value
    const validateSeconds = (value: string) => {
      let seconds = value;

      if (value.indexOf(":") >= 1) {
        seconds = value.split(":")[1];
      } else if (value == "0" || value == undefined || value == "") {
        return "00";
      }

      if (seconds.indexOf(".") >= 0) {
        seconds = seconds.replace(".", ":").split(":")[1];
      }

      const secondsNew =
        seconds.match("[0-5]?[0-9]") == null
          ? "00"
          : Number(seconds) <= 0
          ? "00"
          : Number(seconds) >= 60
          ? "59"
          : Number(seconds) < 10
          ? "0" + Number(seconds)
          : seconds.match("[0-5][0-9]") == null
          ? "00"
          : Number(seconds);

      return secondsNew;
    };

    return {
      invalidValue,
      applySuggestion,
      validate: (event: any) => {
        const value = event.target.value;
        if (event.inputType != "deleteContentBackward") {
          if (value.split(":").length >= 3 && event.data == ":") {
            event.target.value = value.replace(":", "");
          } else if (value.length == 3 && value.indexOf(":") == 0) {
            // :50 => 00:50
            event.target.value = "00:" + validateSeconds(value.slice(1));
          } else if (value.length == 2 && value.indexOf(":") == -1) {
            // 20 => 20:
            const result = validateMinutes(value);
            if (result == value) {
              event.target.value = result + ":";
            }
          } else if (value.length > 3) {
            // event.target.value =
            //   validateMinutes(value) + ":" + validateSeconds(value);
          }
        }
      },
      change: (event: any) => {
        let value = event.target.value;
        if (value.length > 3) {
          // value = validateMinutes(value) + ":" + validateSeconds(value, true);
        } else if (value.indexOf(":") >= 0) {
          // 4:4 => 04:04
          // 04:0 => 04:00
          // value = validateMinutes(value) + ":" + validateSeconds(value, true);
        } else if (value.length == 1) {
          // 4 => 04:00
          // 04 => 04:00
          value = validateMinutes(value) + ":" + validateSeconds("00");
        } else if (value.length == 0) {
          value = "00:00";
        }

        event.target.value = value;
        emit("update:modelValue", value);
        emit("update:modelHasError", !!hasError(value));
      },
    };
  },
});
